:root {
  --font-base: "Open Sans", sans-serif;
  --font-alt: "Work Sans", sans-serif;
  --color-orange: #fd7b4d;
  --color-red-orange: #ef4f14;
  --color-white: #fff;
  --color-black: #0c0c0c;
  --color-turquoise: #3a8486;
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--font-base);
  color: white;
}

body {
  background-color: var(--color-white);
}

p {
  line-height: 1.7;
  margin-bottom: 4%;
  color: #000000b2;
}

/*---Navbar---*/
.navbar {
  background: transparent;
  padding: 1% 0;
  position: fixed;
  width: 100%;
  text-align: center;
  z-index: 2;
}

.navbar li {
  list-style: none;
  display: inline-block;
  font-weight: 600;
  padding: 0 4%;
  text-transform: uppercase;
  text-shadow: -1px 1px black;
}

.navbar a {
  text-decoration: none;
}

.navbar a:hover {
  color: #11abb0;
  transition: color 250ms;
}

.navbar a:after {
  display: block;
  content: "";
  padding-bottom: 2px;
  border-bottom: 2px solid var(--color-orange);
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.navbar a:hover:after {
  transform: scaleX(1);
}
.navbar a.fromRight:after {
  transform-origin: 100% 50%;
}
.navbar a.fromLeft:after {
  transform-origin: 0% 50%;
}

/*adds black background to navbar when scrolling*/
.navbar.scrolled {
  background: rgba(7, 9, 14, 0.8);
  transition: background 800ms;
}

.burger-nav {
  display: none;
}

#home {
  background-image: url("https://images.unsplash.com/photo-1462332420958-a05d1e002413?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1207&q=80");
  min-height: 500px;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  /* padding: 0 5%; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-wrap: wrap;
}

.astronaut {
  transform: translateY(-5%);
  -webkit-animation: floating 3s infinite;
          animation: floating 3s infinite;
  transition: ease 0.5s;
}

@-webkit-keyframes floating {
  0% {
    transform: translateY(-5%);
    transition: ease 0.5s;
  }
  50% {
    transform: translateY(5%);
    transition: ease 0.5s;
  }
}

@keyframes floating {
  0% {
    transform: translateY(-5%);
    transition: ease 0.5s;
  }
  50% {
    transform: translateY(5%);
    transition: ease 0.5s;
  }
}

.chevron-button {
  display: block;
}

.fa-chevron-circle-down {
  color: var(--color-orange);
}
.fa-chevron-circle-down:hover {
  color: var(--color-turquoise);
  transition: color 250ms;
}

.intro-container {
  font-size: 1.5rem;
  font-weight: 600;
  text-shadow: 1px 1px black;
  border-radius: 3px;
  text-align: center;
}

.greeting {
  font-size: 1.77rem;
  color: var(--color-white);
}

/*---sections---*/
section {
  padding: 5% 10%;
  min-height: 600px;
}

h2,
h3,
h4, 
a,
.greeting,
.dev-title {
  font-family: var(--font-alt);
}

section h2 {
  margin: 0 0 2% 3%;
  color: var(--color-black);
  padding-bottom: 1rem;
  text-transform: uppercase;
}

section h2::after {
  content: " ";
  display: block;
  width: 70px;
  border: 2px solid var(--color-orange);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

/*---About---*/

.about-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  font-size: 1.1rem;
}

.about-container-pic {
  text-align: center;
  max-width: 600px;
}

.profile-pic {
  width: 50%;
  border-radius: 4px;
}

.about-description {
  width: 100%;
  max-width: 600px;
}

/*---Skills---*/
/*skills container*/
#skills {
  background-image: url("https://images.unsplash.com/photo-1519681393784-d120267933ba");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.skills-wrapper {
  position: relative;
}

.skills-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* background: rgba(0, 0, 0, 0.2);8 */
}

#skills h3 {
  padding-top: 5%;
}

.skills-panels-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1%;
}

.skills-panel {
  font-weight: bold;
  margin-bottom: 10%;
  border-radius: 5px;
  border: 1px solid white;
  border: var(--color-orange) 1px dashed;
  background-color: rgba(5, 5, 5, 0.7);
}

#skills h2 {
  color: white;
  z-index: 1;
}
.skills-title {
  margin-left: 10%;
}

.skills-panel ul {
  list-style: none;
  text-decoration: none;
  font-weight: 500;
  text-shadow: 1px 1px 1px #565353;
  padding: 15% 10%;
}

.skills-panel li {
  padding-top: 4%;
}

.skills-panel hr {
  color: #5cd4d8;
}

/*Skills bulletpoint*/
/* .fa-circle-notch {
  color: var(--color-orange);
  color: white;
  font-size: 75%;
} */

/*---Project---*/

/*Project container*/
.projects-container {
  display: flex;
  flex-wrap: wrap;
  padding: 3%;
  justify-content: space-around;
  font-size: 1.05rem;
}

.project-image {
  border: 1px rgb(218, 217, 217) solid;
  box-shadow: 5px 5px 2px rgb(153, 152, 152);
  width: 100%;
  max-width: 530px;
  max-height: 320px;
  transition: width .5s;
}

a .project-image:hover {
  width: 98%;
}

.project-description-card {
  width: 500px;
  margin-bottom: 10%;
}

.project-description-card h4 {
  color: var(--color-black);
  padding-bottom: 1rem;
  font-size: 1.4em;
  font-weight: 300;
}

.project-links {
  margin-top: 5%;
}
.project-links > a:first-child {
  margin-right: 5%;
  background-color: var(--color-red-orange);
}
.project-links > a:first-child:hover {
  background-color: var(--color-orange);
}
.project-links > a {
  font-weight: 600;
  text-decoration: none;
  text-shadow: 1px 1px rgb(30, 30, 30);
  padding: 1% 4%;
  background-color: var(--color-turquoise);
  border-radius: 2px;
  box-shadow: 4px 4px 2px rgb(153, 152, 152);
}
.project-links > a:hover {
  background-color: #4aa2a5;
}

/*---Contact---*/

/*---Contact---*/
#contact {
  font-size: 1.4rem;
  background-image: url("https://images.unsplash.com/photo-1592636120953-3d2b28ebfd69");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100vh;
}

.contact-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.left-container {
  width: 500px;
}

.right-container {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#contact div {
  margin: 3%;
}

#contact h2 {
  color: var(--color-white);
}

.download-btn,
.contact-btn {
  width: 200px;
  height: 50px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 2px;
  box-shadow: 6px 6px 3px rgb(41, 41, 41);
  text-shadow: 1px 1px rgb(30, 30, 30);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fa-cloud-download-alt {
  margin-right: 0.5rem;
}

.contact-btn i {
  margin-right: 0.5rem;
}

.contact-btn {
  background-color: var(--color-turquoise);
  position: relative;
  overflow: hidden;
  transition: box-shadow 2s;
}
.contact-btn a,
.download-btn a {
  z-index: 1;
}

.download-btn {
  background-color: var(--color-red-orange);
  position: relative;
  overflow: hidden;
  transition: box-shadow 2s;
}

#slide-1 {
  position: absolute;
  left: -250px;
  width: 210px;
  height: 100px;
  background-color: #4aa2a5;
  transition: 1s;
}

#slide-2 {
  position: absolute;
  left: -250px;
  width: 210px;
  height: 100px;
  background-color: var(--color-orange);
  transition: 1s;
}

.contact-btn:hover,
.download-btn:hover {
  box-shadow: 6px 6px 3px rgb(229, 229, 229);
}

.download-btn:hover #slide-2,
.contact-btn:hover #slide-1 {
  transition: 1s;
  left: -10px;
}

.social-media {
  display: flex;
  justify-content: space-around;
}

.social-media i {
  font-size: 300%;
}

.fa-caret-square-up {
  font-size: 250%;
  float: right;
  color: var(--color-red-orange);
  transition: color 1s;
}

.fa-caret-square-up:hover {
  color: var(--color-orange);
}

/*---Footer---*/
#Footer {
  background-color: var(--color-black);
  height: 40px;
  text-transform: uppercase;
  /* font-size: 1.2rem; */
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*----animation----*/

/*controls skills card flip*/
.js--wp-skills-1,
.js--wp-skills-2,
.js--wp-skills-3 {
  opacity: 0;
}

.js--wp-skills-1.animated {
  opacity: 1;
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.js--wp-skills-2.animated {
  opacity: 1;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.js--wp-skills-3.animated {
  opacity: 1;
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.js--wp-about-pic {
  opacity: 0;
}

.js--wp-about-pic.animated,
.js--wp-about.animated {
  opacity: 1;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

/*skills*/
.js--wp-projects {
  opacity: 0;
}
.js--wp-projects.animated {
  opacity: 1;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

@media (min-width: 414px) {
  .intro-container {
    font-size: 1.5rem;
  }

  .greeting {
    font-size: 2rem;
  }
}


@media (min-width: 540px) {
  .intro-container {
    font-size: 2rem;
  }

  .greeting {
    font-size: 2.5rem;
  }
}

@media (min-width: 768px) {
  .intro-container {
    font-size: 2.5rem;
  }

  .greeting {
    font-size: 3.5rem;
  }
}

@media (min-width: 820px) {
  .intro-container {
    font-size: 2.8rem;
  }

  .greeting {
    font-size: 4rem;
  }
}

@media (min-width: 901px) {
  .projects-container {
    padding: 0 3%;
  }
}

/*controls navbar collapsed setup*/
@media (max-width: 900px) {
  #mainnavbar .navbar {
    overflow: hidden;
    position: absolute;
    right: 50px;
    top: 50px;
    display: block;
  }

  .navbar.scrolled {
    background-color: transparent;
    transition: background 800ms;
  }

  .burger-nav {
    background-color: rgba(7, 9, 14, 0.8);
    font-size: 220%;
    display: block;
    float: right;
    margin-right: 10px;
    height: 50px;
    width: 50px;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 3px;
  }

  .burger-nav i {
    color: var(--color-orange);
  }

  /*hides navigation list*/
  .container div ul {
    height: 0px;
    overflow: hidden;
    border-radius: 5px;
  }

  .container div ul.open {
    height: auto;
    float: right;
    clear: both;
  }

  .container div ul li {
    float: none;
    width: auto;
    min-width: 200px;
    margin: 0;
    display: block;
    margin: 5px;
    background: #282828;
    border-radius: 3px;
    border: 1px solid rgb(134, 133, 133);
    box-shadow: 1px 1px 1px white;
  }

  .container div ul li a {
    color: var(--color-orange);
    padding: 10px;
    display: block;
  }

  .container div ul li a:active {
    border: none;
    color: #11abb0;
  }
}

